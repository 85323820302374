.affiche__tags{
    margin-bottom: 1em;
}
.affiche__tag{
    font-size: 1em;
    padding: 5px 1em;
    background-color: #E7EBF3;
    border: 0;

}
.affiche__tags-container{
    overflow: hidden;
}

.affiche__tag.ant-tag-checkable:active {
    background-color: #E7EBF3;
    color:#1890ff;
}
.affiche__tag.ant-tag-checkable-checked{
    background-color: #4376da;
    color:white;
}

.affiche__detail_header{
    padding: 150px 50px 50px;
    margin: 0px -50px 50px;
}

.ant-carousel{
    width: 100%;
}

.slick-dots li button{
    background: black;
}
.slick-dots .slick-active button{
    background: #2e96fe!important;
}
.slick-dots button{
    background: white!important;
    opacity: 1;
}
.slick-dots-top{
    bottom: unset!important;
}

@media all and (max-width:768px){
    .affiche__detail_header{
        padding: 150px 1em 50px!important;
        margin: 0px -1em 50px;
    }
    .recommend_apps.ant-drawer .ant-drawer-content{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow: visible;
    }
    .affiche__tags-items{
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .affiche__detail_container{
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }
}