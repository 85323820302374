.affiche__container{
    border-radius: 5px;
}

.affiche__preview:hover{
    -webkit-transform: translate(-5px, -5px);
        -ms-transform: translate(-5px, -5px);
            transform: translate(-5px, -5px);
    -webkit-box-shadow: 5px 5px 5px #D1DDEA;
            box-shadow: 5px 5px 5px #D1DDEA;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.affiche__preview{
    text-align: center;
    overflow: hidden;
    background: #eeeeee;
    padding-top: 56.25%; /* 16:9*/
    position: relative;
    width: 100%;
    max-height: 236px;
    border-radius: 5px;
    -webkit-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    display: inline-block;
    position: relative;
}

.affiche__image{
    position: absolute;
    height: auto;
    width: auto;
    top: 0;
    left: 0;
    max-height: 100%;
    max-width: 100%;
}

.affiche_external_card{
    padding: 5px 10px;
}