
.chat{
    position: fixed;
    background: #eeeeee;
    overflow: hidden;
    border-radius: 1em;
    z-index: 100;
    transition: .3s;
}

.chat__container{
    overflow-x: hidden;
    max-height: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.chat__mobile{
    top: 0; bottom: 0; right: 0; height: 0;
    width: 100%; height: 100%;
}

.chat__desktop{
    width: 400px; 
    top: 20%;
    bottom: 120px;
    right: 50px;
}

.chat__system_message{
    text-align: center;
    margin: 1em 0 0 0;
    font-style: italic; 
}

.chat__fullscreen{
    width: 50%;
    top: 100px; 
    bottom: 120px;
    right: 50px;
}

.chat__container::-webkit-scrollbar{
    width: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chat__button_container{
    position: fixed;
    bottom: 50px;
    right: 50px;
}

.chat__button{
    width: 50px; 
    height: 50px; 
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.chat__button:hover{
    background: #1890FF;
}

.chat__button:hover > .chat__icon{
    color: white;
}

.chat__support_header{
    width: 100%;
    text-align: center;
    cursor: pointer;
    background: white;
    padding: 1em;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.05);
    transition: .3s;
}

.chat__support_header:hover{
    background: #1890FF;
    color: white;
    transition: .3s;
}

[data-theme='compact'] .chat__collapse_custom{
    margin-bottom: 24px;
    overflow: hidden;
    background: #f7f7f7;
    border: 0px;
    border-radius: 2px;
}

.chat__list_item{
    transition: .3s;
}

.chat__list_item:hover{
    background: #1890FF;
    color: white;
    transition: .3s;
}

.chat__area{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    overflow: hidden;
}

.chat__messages{
    overflow-y: scroll;
    height: 100%;
    padding: 0;
}
.chat__messages::-webkit-scrollbar{
    width: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chat__messages_items{
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    list-style: none;
}

.chat__messages_item{
    margin: 1em 0 0 0;
    align-self: flex-start;
    word-break: break-word;
    display: flex;
    max-width: 75%;
}

.chat__message{
    padding: 5px 1em;
    margin: 0 0 0 5px;
    background: white;
    border-radius: 1em;
}

.chat__user_message{
    align-self: flex-end;
    
}

.chat__user_self_message{
    background: lightsteelblue;
}

.chat__message_avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 22px 0 0 0;
}

.chat__icon{
    color: #1890FF;
    font-size: 2em;
}