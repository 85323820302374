.mobile_apps{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}