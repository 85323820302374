* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root{
  height: 100%;
}

body {
  /* background-color: #F5FDFF!important; */
  background-color: rgb(247, 248, 250)!important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'!important;
  overflow-y: scroll;
}

h4{
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper{
  background: transparent!important;
  min-height: 100vh;
  position: relative;
}

.wrapper__container{
  max-width: 820px;
  padding: 15px;
  margin: auto;
}

.form__wrapper{
  border-radius: 4px;
  max-width: 100%;
  background: #fff;
  padding: 2em 0;
  margin-bottom: 40px;
  -webkit-box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.05);
}
/* checkbox and radio corners */
.form_corners_left{
  border-top-left-radius: 4px!important;
  border-bottom-left-radius: 4px!important;
}

.form_corners_right{
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
}

.modal__confidence{
  width: 70%;
  margin: 0 70px;
}

.modal__confidence_button{
  padding-left: 35px!important;
}

@media all and (max-width: 575px){
  .wrapper__text{
    padding: 0 20px;
  }
  h1{
    font-size: 32px!important;
  }
  h2{
    font-size: 24px!important;
  }
  .wrapper__container{
    max-width: 820px;
    padding: 15px 0 0 0;
    margin: auto;
  }
  .form__wrapper{
    border-radius: 4px;
    max-width: 100%;
    background: #fff;
    padding: 2em 20px;
    -webkit-box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.05);
  }
}

html {
  -webkit-font-smoothing: antialiased;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* ---MODAL CONFIDENCE---- */
@media all and (max-width: 425px){
  .modal__confidence{
    width: 100%;
    margin: 0;
  }
  
  .modal__confidence_button{
    padding-left: 30px!important;
  }

}

@media all and (max-width: 321px){

  .modal__confidence_button{
    padding-left: 30px!important;
  }
}

@media all and (max-width: 321px){
  .modal__confidence_button{
    padding-left: 25px!important;
  }
}