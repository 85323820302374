.fluid-container{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}

.fluid-container-main{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 50px 2.5rem;
}

.header{
    height: 80px;
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    z-index: 50;
}

.footer{
    background: #1F232C;
}
.setup{
    font-family: 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'!important;
}

.color__font-white a, .color__font-white li, 
.color__font-white p, .color__font-white h1,
.color__font-white h2, .color__font-white h3,
.color__font-white h4, .color__font-white h5,
.color__font-white h6{
    color: white;
}

.color__font-white a:hover{
    color: #40a9ff;
}

.card__hover{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 1em;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    opacity: 0;
    background: rgba(31,35,44, .8);
    -webkit-transition-duration: .3s;
         -o-transition-duration: .3s;
            transition-duration: .3s;
}

.card__hover:hover{
    -webkit-transition-duration: .3s;
         -o-transition-duration: .3s;
            transition-duration: .3s;
    opacity: 1;
}


.tag--green{
    display: inline-block;
    padding: 5px 1em;
    background: #37C573;
    border-radius: 25px;
    color: white;
}

.tag--yellow{
    display: inline-block;
    padding: 5px 1em;
    background: #F0DE34;
    border-radius: 25px;
}

.tag--left-top{
    position: absolute;
    top: 15px;
    left: 15px;
}

.tag--right-top{
    position: absolute;
    top: 15px;
    right: 15px;
}

.tag__live{
    position: absolute;
    padding: 5px 1em;
    background: #F63E3E;
    border-radius: 25px;
}

.tag__live--min{
    width: 18px;
    height: 18px;
    background: #F63E3E;
    border-radius: 50%;
}



.navbar__item{
    margin: 0 20px;
    color: black;
}

.autosize-container {
    overflow: hidden;
    padding-top: 56.25%; /* 16:9*/
    position: relative;
    width: 100%;
  }
  
.autosize-container iframe, .autosize-container span {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%!important;
}

.autosize-test{
    overflow: hidden;
    padding-top: 56.25%; /* 16:9*/
    position: relative;
    width: 100%;
}

.account{
    font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
}

.autosize-child{
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%!important;
}

.navigation__mobile{
    display: none;
}


.user__board_score{
    width: 100%;
    margin: 0 0 20px 0;
    padding: 50px 0;
    background: #F0F3F8;
}

.title__link{
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}
.shop__user-items{
    white-space: nowrap;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.shop__user-items::-webkit-scrollbar {
    display:none;
}
@media all and (max-width: 992px){
    .user__shop{
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }
    .navigation__desktop{
        display: none;
    }
    .navigation__mobile{
        
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media all and (max-width: 768px){
    
    .fluid-container{
        padding: 0 1em!important;
        width: 70%;
        margin: 0 auto;
    }
    .footer{
        padding-top: 2em!important;
        padding-bottom: 2em!important;
    }
    .fluid-container-main{
        padding: 0 1em!important;
        width: 70%;
        margin: 0 auto;
    }
    .shop__user-items{
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .title__link{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: baseline;
            -ms-flex-align: baseline;
                align-items: baseline;
    }
    .user__board_info{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 3em;
    }
    .user__board_info-item{
        margin-bottom: 1em;
    }
}

@media all and (max-width: 576px){
    .header__logo-footer{
        margin: 50px 0;
        max-width: 200px;
    }

    .fluid-container{
        max-width: 576px;
        width: 100%;
        margin: 0 auto;
    }
    .fluid-container-main{
        max-width: 576px;
        padding: 0 1em!important;
        width: 100%;
        margin: 0 auto;
    }
}