.panel__container{
    margin: 2em auto 25px;
}

.title__link{
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}